<template>
  <div class="row">
    <div
      class="flex xs12"
      v-if="tabs.length > 0"
    >
      <va-card>
        <tabs-container :tabs="tabs">
          <template v-slot:region>
            <monthly-bar
              selection="region"
              :loading="loading.monthly.region"
              @submit="regionAssignment"
            />
            <assignment-report
              v-show="show.region"
              :loading="loading.monthly.region"
              :reports-list="tableData.assignments.region"
              @export="prepareDownload('region')"
            />
          </template>
          <template v-slot:country>
            <monthly-bar
              selection="country"
              :loading="loading.monthly.country"
              @submit="countryAssignment"
            />
            <assignment-report
              v-show="show.country"
              :loading="loading.monthly.country"
              :reports-list="tableData.assignments.country"
              @export="prepareDownload('country')"
            />
          </template>
          <template v-slot:district>
            <monthly-bar
              selection="district"
              :loading="loading.monthly.district"
              @submit="districtAssignment"
            />
            <assignment-report
              v-show="show.district"
              :loading="loading.monthly.district"
              :reports-list="tableData.assignments.district"
              @export="prepareDownload('district')"
            />
          </template>
        </tabs-container>
      </va-card>
    </div>
    <div class="flex xs12">
      <va-card :title="$t('reports.assignments.index')">
        <remote-table
          class="table-hover table-striped"
          :columns="tableFields"
          :data="tableData.total"
          :loading="loading.total"
          :search="searchOptions"
          :pagination="pagination"
          :action-options="['edit', 'delete']"
          controller="assignments-jfhp"
          crud-links="assignments"
          @update-data="updateData"
          @search-data="searchByName"
          @delete-item="tryDelete"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const MonthlyBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Monthly')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')
const AssignmentReport = () => import(/* webpackPrefetch: true */ './Report')

export default {
  name: 'assignments-index',
  components: {
    MonthlyBar,
    RemoteTable,
    TabsContainer,
    AssignmentReport,
  },
  data () {
    return {
      error: false,
      pagination: {},
      tableData: {
        assignments: {
          region: [],
          country: [],
          district: [],
        },
        total: [],
      },
      show: {
        region: false,
        country: false,
        district: false,
      },
      searchQuery: '',
      loading: {
        monthly: {
          region: false,
          country: false,
          district: false,
        },
        total: false,
      },
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
        // externalQuery: searchQuery
      },
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '', // 'asc' or 'desc'
        },

        page: 1, // what page I want to show
        perPage: 50, // how many items I'm showing per page
      },
      currentQuery: {
        region: {},
        country: {},
        district: {},
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tableFields () {
      return [
        {
          name: 'region_name',
          title: this.$t('tables.headings.region'),
          visible: this.currentUser.can('Regions', 'index'),
          sortField: 'Regions.name',
          callback: this.translatedName,
        },
        {
          name: 'region_assignment',
          title: '%',
          callback: this.assignmentFormat,
          visible: this.currentUser.can('Regions', 'index'),
          sortField: 'region_assignment',
        },
        {
          name: 'country_name',
          title: this.$t('tables.headings.country'),
          visible: this.currentUser.can('Countries', 'index'),
          sortField: 'Countries.name',
          callback: this.translatedName,
        },
        {
          name: 'country_assignment',
          title: '%',
          callback: this.assignmentFormat,
          visible: this.currentUser.can('Countries', 'index'),
          sortField: 'country_assignment',
        },
        {
          name: 'team_code',
          title: this.$t('tables.headings.team'),
          callback: this.translatedName,
          sortField: 'Teams.code',
        },
        {
          name: 'team_assignment',
          title: '%',
          callback: this.assignmentFormat,
          sortField: 'team_assignment',
        },
        {
          name: 'value',
          callback: this.totalFormat,
          title: this.$t('tables.headings.total'),
          sortField: 'value',
        },
        {
          name: '__slot:actions',
          visible: this.tableData.total.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
    tabs () {
      const t = []
      if (this.currentUser.can('Regions', 'index')) {
        t.push({ title: this.$t('reports.assignments.region'), name: 'region' })
      }
      if (this.currentUser.can('Countries', 'index')) {
        t.push({ title: this.$t('reports.assignments.country'), name: 'country' })
      }
      if (this.currentUser.can('Districts', 'index')) {
        t.push({ title: this.$t('reports.assignments.district'), name: 'district' })
      }

      return t
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    translatedName (name) {
      return this.$t(name)
    },
    assignmentFormat (assignment) {
      return parseFloat(assignment * 100).toFixed(2) + '%'
    },
    totalFormat (total) {
      return '$' + parseFloat(total).toFixed(2)
    },
    fixData (data) {
      for (const d of data) {
        if (d.region) {
          d.region_name = d.region.name
        }

        if (d.country) {
          d.country_name = d.country.name
          if (!d.region_name && d.country.region) {
            d.region_name = d.country.region.name
          }
        }

        if (d.team) {
          d.team_code = d.team.code
          if (!d.country_name && d.team.country) {
            d.country_name = d.team.country.name
            if (!d.region_name && d.team.country.region) {
              d.region_name = d.team.country.region.name
            }
          }
        }

        if (!d.region_name) {
          d.region_name = 'assignments.selects.any_region'
        }
        if (!d.country_name) {
          d.country_name = 'assignments.selects.any_country'
        }
        if (!d.team_code) {
          d.team_code = 'assignments.selects.any_team'
        }
      }
      return data
    },
    apiUrl (params) {
      let route = 'reports/assignments'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        let field = params.sort.field
        switch (field) {
          case 'team.code':
            field = 'Teams.code'
            break
          case 'district.country.name':
            field = 'Countries.name'
            break
          case 'district.country.region.name':
            field = 'Regions.name'
            break
        }
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading.total = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading.total = false
        this.error = true
        return
      }

      this.tableData.total = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading.total = false
    },
    async searchByName (name) {
      if (name === '') return this.updateData()

      let response = false
      try {
        response = await this.$http.get('assignments-jfhp?q=' + name)
      } catch (err) {
        // console.log('Error searching assignment', err)
        this.loading.total = false
        return
      }

      this.tableData.total = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading.total = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading.total = true
      try {
        await this.$http.delete('reports/assignments/' + item.id)
      } catch (err) {
        // console.log('Error deleting assignment', err)
        this.loading.total = false
        return
      }
      this.updateData()
    },
    regionAssignment (data) {
      const d = {
        type: 'region',
        date: data.date,
        id: data.region,
      }
      this.getMonthlyAssignment(d)
    },
    countryAssignment (data) {
      const d = {
        type: 'country',
        date: data.date,
        id: data.country,
      }
      this.getMonthlyAssignment(d)
    },
    districtAssignment (data) {
      const d = {
        type: 'district',
        date: data.date,
        id: data.district,
      }
      this.getMonthlyAssignment(d)
    },
    async getMonthlyAssignment (data) {
      this.currentQuery[data.type] = data
      this.loading.monthly[data.type] = true
      this.show[data.type] = true

      let response = false
      try {
        response = await this.$http.post('proyections/assignments', data)
      } catch (e) {
        // console.log('Cant get assignments', e)
        this.loading.monthly[data.type] = false
        return
      }

      this.tableData.assignments[data.type] = response.data.data || []
      this.loading.monthly[data.type] = false
    },
    async prepareDownload (type) {
      const prepareRoute = 'proyections/assignments?export=xlsx'
      const downloadRoute = 'proyections/download/'

      return this.downloadFile(this.currentQuery[type], prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
